import {Box, Container} from '@chakra-ui/react';
import NextImage from 'next/legacy/image';

import {ScrollableFades} from '@eksab/components/ScrollableFades';

import {useWinnersQuery} from '../hooks/useWinnersQuery';

export function Winners() {
  const {data: winners} = useWinnersQuery();

  return (
    <Container variant="scroll" gap={[3, null, 5]}>
      <ScrollableFades bgColor="white" />

      {winners?.map((winner) => (
        <Box rounded="md" key={winner.id} overflow="hidden" minW="280px" h="408px">
          <NextImage src={winner.image?.original!} alt="Eksab winner" width="300" height="408" objectFit="cover" />
        </Box>
      ))}
    </Container>
  );
}
