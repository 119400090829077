import {useState, useEffect, useRef} from 'react';
import {Container, Flex, Text, Heading, useBreakpointValue} from '@chakra-ui/react';

import {px} from '@eksab/theme';
import {useTranslate} from '@eksab/i18n';
import {ScrollableFades} from '@eksab/components/ScrollableFades';
import {useInfiniteHorizontalScroll, useLeaguesQuery, type League} from '@eksab/hooks';
import {CompetitionCard} from '@eksab/features/competition/components/competition-card/CompetitionCard';

import {Filters, useFiltersState} from '@eksab/features/competition/components/filters/Filters';
import {useCompetitionsQuery} from '@eksab/features/competition/hooks';

export function Leagues() {
  const {data: leagues} = useLeaguesQuery();
  const [activeLeague, setActiveLeague] = useState<League | undefined>(leagues?.[0]);
  const [filters, setFilters, count] = useFiltersState();
  const t = useTranslate();
  const {data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage} = useCompetitionsQuery(filters);
  const numberOfSkeletons = useBreakpointValue({base: 1, md: 2})!;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useInfiniteHorizontalScroll(scrollContainerRef, {
    condition: !isFetchingNextPage && hasNextPage,
    onScrollEnd: fetchNextPage,
  });

  const competitions = data?.pages?.flatMap((page) => page.data);

  useEffect(() => {
    if (leagues?.length) setActiveLeague(leagues[0]);
  }, [leagues]);

  return (
    <>
      <Flex justify="space-between" mb="6">
        <Heading size="2xl">{t('group.competitions')}</Heading>
        <Filters filterByType initialState={filters} onSubmit={setFilters} count={count} />
      </Flex>

      <Container ref={scrollContainerRef} variant="scroll" py="5" gap="2.5">
        <ScrollableFades bgColor="white" />

        {isError ? (
          <Text>{t('app.errors.try-again')}</Text>
        ) : isLoading ? (
          Array.from({length: 5}).map((_, idx) => (
            <CompetitionCard.Skeleton key={idx} width={[`calc(100vw - ${px[0]! * 4 * 2}px)`, '23.75rem']} />
          ))
        ) : !competitions?.length ? (
          <Text>
            {t('competitions.no-competitions', {
              filter: activeLeague?.name,
              br: () => <br />,
            })}
          </Text>
        ) : (
          <>
            {competitions?.map((competition) => (
              <CompetitionCard
                key={competition.id}
                competition={competition}
                prioritizeTypeOverState
                w={[`calc(100vw - ${px[0]! * 4 * 2}px)`, '23.75rem']}
              />
            ))}

            {isFetchingNextPage &&
              Array.from({length: numberOfSkeletons}).map((_, idx) => (
                <CompetitionCard.Skeleton
                  key={idx}
                  width={[`calc(100vw - ${px[0]! * 4 * 2}px)`, '23.75rem']}
                  height="auto"
                />
              ))}
          </>
        )}
      </Container>
    </>
  );
}
