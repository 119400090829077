import {BoxProps, Button, Flex, Text} from '@chakra-ui/react';
import {CoinsIcon} from '@eksab/assets/icons/CoinsIcon';
import {useTranslate} from '@eksab/i18n';
import {useRouter} from 'next/router';

export function SignUpPromo(props: BoxProps) {
  const t = useTranslate();
  const router = useRouter();
  return (
    <Flex align="center" bgColor="gray.900" borderRadius="md" p="25px" pt="11px" flexWrap="wrap" {...props}>
      <Flex mt="3.5">
        <CoinsIcon />
        <Text color="white" ms="2.5" me="7">
          {t('home.sign_up_promo', {
            b: (chunks) => (
              <Text as="span" color="green.400" fontWeight="bold">
                {chunks}
              </Text>
            ),
          })}
        </Text>
      </Flex>
      <Button flexGrow="1" mt="3.5" onClick={() => router.push('/register')}>
        {t('app.actions.register')}
      </Button>
    </Flex>
  );
}
