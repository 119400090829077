import Image from 'next/legacy/image';
import {useIntl} from 'react-intl';
import {Box, Flex, /*Center, Flex,*/ Grid, Text} from '@chakra-ui/react';
// import {ChevronLeftIcon} from '@chakra-ui/icons';

import {Card} from '@eksab/components/Card';
import {ProfileIcon} from '@eksab/assets/icons/ProfileIcon';

import {useTopWinners, type Winner} from '../hooks';

export function TopWinners() {
  const {data: winners} = useTopWinners();

  return (
    <Grid w="100%" templateColumns={['unset', null, 'repeat(2, 1fr)']} gap="4">
      {winners?.map((winner) => <WinnerCard key={winner.id} winner={winner} />)}
    </Grid>
  );
}

const WinnerCard = ({winner: {name, photo, total_amount: moneyEarned}}: {winner: Winner}) => {
  const format = useIntl().formatNumber;

  const formatCurrency = (money: number) =>
    format(money, {style: 'currency', currency: 'EGP', maximumFractionDigits: 0});

  return (
    <Card
      display="flex"
      gap="3"
      justifyContent="space-between"
      ps="6"
      bgColor="white"
      h={[20, null, '6.25rem']}
      alignItems="center"
      pos="relative"
      overflow="hidden"
      // pe="10"
    >
      <Flex flex={1} align="center" gap="3">
        <Box pos="relative" rounded="50%" boxSize="12" minW="12" overflow="hidden">
          {photo?.thumb ?? photo?.original ? (
            <Image layout="fill" objectFit="cover" src={photo?.thumb ?? photo?.original} alt={name} />
          ) : (
            <ProfileIcon width="full" height="auto" />
          )}
        </Box>

        <Text title={name} noOfLines={2}>
          {name}
        </Text>
      </Flex>

      <Text fontWeight="800" me="6" sx={{fontVariantNumeric: 'tabular-nums'}} textAlign="end">
        {formatCurrency(+moneyEarned)}
      </Text>

      {/* <Box
          bgColor="gray.400"
          h="100%"
          w="10"
          display="flex"
          className="ranking-card"
          overflow="hidden"
          pos="absolute"
          flexShrink="0"
          transition="width 0.5s, background-color  0.5s"
          insetEnd={0}
          _hover={{w: ['100%', null, '310px'], bgColor: 'gray.700'}}>
          <Center flexShrink="0" w="10" h="100%" boxShadow="base">
            <ChevronLeftIcon
              alignSelf="center"
              color="white"
              transition="transform 0.5s"
              sx={{
                '.ranking-card:hover &': {
                  transform: 'rotate(180deg)',
                },
              }}
            />
          </Center>
        </Box> */}
    </Card>
  );
};
