import NextLink from 'next/link';
import {Button, Box, Container, Heading, Text, Image} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {PlaygroundBackground} from '@eksab/components/PlaygroundBackground';
import {CompetitionType} from '@eksab/features/competition/types';
import {usePrefetchCompetition} from '@eksab/features/competition/hooks/useCompetitionQuery';
import {useAccessToken} from '@eksab/hooks';

import {useFeaturedCompetitionQuery} from '../hooks';
import {useEffect} from 'react';

export function FeaturedCompetition() {
  const prefetchCompetition = usePrefetchCompetition();
  const t = useTranslate();
  const featuredCompetitionQuery = useFeaturedCompetitionQuery();

  const isLoggedIn = useAccessToken().isSuccess;

  const featuredCompetitionImage =
    featuredCompetitionQuery.data?.sponsors?.[0]?.logo?.original ??
    featuredCompetitionQuery.data?.prizes?.[0]?.image?.original;

  useEffect(() => {
    if (isLoggedIn) {
      featuredCompetitionQuery.data?.id && prefetchCompetition(featuredCompetitionQuery.data.id, 'user');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (!featuredCompetitionQuery.data) return null;

  return (
    <Box pos="relative" w="100%">
      <PlaygroundBackground />

      <Container gap={[4, 6, 8, 10]} alignItems="center" py={['10', null, '20']}>
        <Box mb={['10', null, 'unset']}>
          <Text color="green.400" mb="1" fontWeight="black" textTransform="capitalize">
            {t(`competition.header.state.${featuredCompetitionQuery.data?.featured ? 'featured' : 'upcoming'}`)}
          </Text>

          <Heading size="4xl" color="white" mb={[4, null, 9]} maxW="21ch">
            {featuredCompetitionQuery.data?.name}
          </Heading>

          <NextLink
            passHref
            href={{
              pathname: `/${
                featuredCompetitionQuery.data?.type === CompetitionType.FANTASY ? 'fantasy' : 'predictions'
              }/[slug]`,
              query: {slug: featuredCompetitionQuery.data.id},
            }}
            legacyBehavior
          >
            <Button as="a" title={featuredCompetitionQuery.data?.name}>
              {t('home.play_now')}
            </Button>
          </NextLink>
        </Box>

        {!!featuredCompetitionImage && (
          <Image
            flexShrink={3}
            w="full"
            maxW={[28, 40, 48, 60]}
            maxH="48"
            objectFit="contain"
            ms="auto"
            alt=""
            src={featuredCompetitionImage}
          />
        )}
      </Container>
    </Box>
  );
}
