import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';
import {useQueryClient} from '@tanstack/react-query';
import {useRouter} from 'next/router';

type News = {
  id: number;
  slug: string;
  title: string;
  body: string;
  published_at: string;
  image: Media;
};

const queryKey = (isLoggedIn: boolean) => ['news', 'latest', isLoggedIn];

const queryFn = (isLoggedIn: boolean) => () =>
  api.get(`v3/news${isLoggedIn ? '/auth' : ''}?per_page=10`).then((res) => res.data.data);

export function useNewsQuery() {
  const isLoggedIn = !!useAccessToken()?.data;
  const queryClient = useQueryClient();
  const locale = useRouter().locale;
  const placeholderData = queryClient.getQueryData<News[]>([...queryKey(false), locale])!;

  return useQuery<News[]>(queryKey(isLoggedIn), queryFn(isLoggedIn), {placeholderData});
}

useNewsQuery.queryKey = queryKey;
useNewsQuery.queryFn = queryFn;
