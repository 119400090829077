import {AxiosError} from 'axios';
import type {UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useAccessToken, useQuery} from '@eksab/hooks';
import {Competition} from '@eksab/features/competition/types';

const queryKey = (leagueID: number, isLoggedIn: boolean) => ['competitions-by-league', leagueID, isLoggedIn];

const queryFn = (leagueID: number, isLoggedIn: boolean) => () =>
  api
    .get(
      `/v2/competitions${isLoggedIn ? '/auth' : ''}?expected-first=true&state=upcoming&has-spots=1&league=${leagueID}`,
    )
    .then((res) => res.data.data);

export const useCompetitionsByLeagueQuery = (
  leagueID: number | undefined,
  options?: UseQueryOptions<Competition[], AxiosError>,
) => {
  const isLoggedIn = !!useAccessToken()?.data;

  return useQuery<Competition[], AxiosError>(queryKey(leagueID!, isLoggedIn), queryFn(leagueID!, isLoggedIn), {
    ...options,
    enabled: !!leagueID && (options?.enabled ?? true),
  });
};

useCompetitionsByLeagueQuery.queryFn = queryFn;
useCompetitionsByLeagueQuery.queryKey = queryKey;
