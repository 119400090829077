import {Box, Button, Container, Flex, Heading, Image, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

export const JoinFacebookGroup = () => {
  const t = useTranslate();

  return (
    <Container
      display="grid"
      w="fit-content"
      py={[6, 9]}
      gridTemplateColumns={['1fr 1fr', null, 'auto 1fr']}
      gridTemplateRows={['1fr auto', null, '1fr 1fr']}
      columnGap={[4, 10]}
      rowGap={[6, 8, 9]}
    >
      <Box alignSelf="flex-end" color="white" maxW="96" gridColumn={['1/-1', '1/2']}>
        <Heading color="inherit" fontSize={['md', 'xl']} fontWeight="800" mb={[2, 4]}>
          {t('home.join-group.title')}
        </Heading>

        <Text color="inherit" fontSize={['sm', 'md']} lineHeight="1.9">
          {t('home.join-group.description')}
        </Text>
      </Box>

      <Flex
        justifySelf="flex-start"
        alignSelf={['flex-end', 'flex-start']}
        flexDir={['column', null, 'row']}
        align={['stretch', null, 'center']}
        gap="2"
      >
        <Button
          as="a"
          href="https://www.facebook.com/groups/eksabapp/"
          target="_blank"
          variant="outline"
          bg="transparent"
          minW="40"
        >
          {t('home.join-group.facebook.action')}
        </Button>

        <Button
          as="a"
          href="https://chat.whatsapp.com/GrLzkEZ873YJCaw9ZnkNjP"
          target="_blank"
          variant="outline"
          bg="transparent"
          minW="40"
        >
          {t('home.join-group.whatsapp.action')}
        </Button>
      </Flex>

      <Image
        justifySelf="center"
        gridColumn="2"
        gridRow={['2/3', '1/3']}
        loading="lazy"
        src="/images/facebook-group.svg"
        alt="eksab's facebook group"
      />
    </Container>
  );
};
