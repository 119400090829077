import {Box, Button, Center, Flex, Heading} from '@chakra-ui/react';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {useTranslate} from '@eksab/i18n';
import Image from 'next/legacy/image';
import {useRouter} from 'next/router';

export function Footer() {
  const t = useTranslate();
  const router = useRouter();

  const {data: regionSettings} = useRegionSettings();

  return (
    <Center pos="relative" h="250px" flexDir="column">
      <Box pos="absolute" w="100%" h="100%" top={0} left={0} zIndex={-1}>
        <Box pos="relative" h="255px" w="100%">
          <Image
            priority
            src={'/images/landing-footer-background.png'}
            alt="Football playground"
            layout="fill"
            objectFit="cover"
          />
        </Box>
      </Box>
      <Heading size="2xl" color="white" mb="5">
        {t('home.ready_to_win')}
      </Heading>
      <Flex direction={['column', null, 'row']} gap="4">
        <Button
          onClick={() => {
            router.push('/fantasy');
          }}
        >
          {t('home.play_fantasy')}
        </Button>
        {!!regionSettings?.settings?.tactic_enabled && (
          <Button
            onClick={() => {
              router.push('/predictions');
            }}
          >
            {t('home.play_predictions')}
          </Button>
        )}
        <Button
          onClick={() => {
            router.push('/trivia');
          }}
        >
          {t('home.play_trivia')}
        </Button>
      </Flex>
    </Center>
  );
}
