import {api} from '@eksab/api';
import {useCompetitionsQuery} from '@eksab/features/competition/hooks';
import {Competition} from '@eksab/features/competition/types';
import {useGetRegionSettingsQueryData, useQuery} from '@eksab/hooks';
import {getCountry, getLanguage} from '@eksab/util';
import {QueryFunctionContext} from '@tanstack/react-query';

const queryKey = ['featured-competition'];

const queryFn = async (context: QueryFunctionContext) => {
  const locale = context.queryKey[context.queryKey.length - 1] as Locale;
  let featuredCompetition = await api
    .get(`/v2/competitions?featured=1&state=upcoming&per_page=1`, {
      headers: {'X-Locale': getLanguage(locale), 'X-REGION': getCountry(locale)},
    })
    .then((res) => res.data.data[0]);

  if (!featuredCompetition)
    featuredCompetition = (
      await useCompetitionsQuery.queryFn(
        {meta: context.meta, queryKey: useCompetitionsQuery.queryKey({}, 'guest', locale)},
        {},
        false,
      )
    ).data[0];

  return featuredCompetition;
};

export function useFeaturedCompetitionQuery() {
  const getRegionSettingsData = useGetRegionSettingsQueryData();

  return useQuery<Competition>(queryKey, queryFn, {
    enabled: false,
    meta: {isTacticsEnabled: getRegionSettingsData()?.settings.tactic_enabled},
  });
}

useFeaturedCompetitionQuery.queryKey = queryKey;
useFeaturedCompetitionQuery.queryFn = queryFn;
