import NextLink from 'next/link';
import {Box, Container, ContainerProps, Flex, Text, Image} from '@chakra-ui/react';
import dayjs from 'dayjs';

import {Card} from '@eksab/components/Card';
import {ScrollableFades} from '@eksab/components/ScrollableFades';

import {useNewsQuery} from '../hooks';

export function LatestNews(props: ContainerProps) {
  const newsQuery = useNewsQuery();

  return (
    <Container variant="scroll" gap="5" overflowY="visible" {...props}>
      <ScrollableFades bgColor="white" />

      {newsQuery.data?.map((pieceOfNews) => <NewsCard {...pieceOfNews} key={pieceOfNews.id} />)}
    </Container>
  );
}

type NewsCardProps = Exclude<ReturnType<typeof useNewsQuery>['data'], undefined>[number];

function NewsCard({title, slug, image, published_at}: NewsCardProps) {
  return (
    <NextLink href={`/news/${slug}`} passHref legacyBehavior>
      <Card as="a" shadow="md" fontFamily="Cairo" dir="auto" maxW="23.75rem" pb="4">
        <Image
          w="100%"
          h="15.8rem"
          borderTopRadius="inherit"
          mb="3"
          src={image?.original}
          alt={title}
          objectFit="cover"
        />

        <Box px="5">
          <Text title={title} fontSize="xl" fontWeight="700" mb="5" noOfLines={2}>
            {title}
          </Text>

          <Flex justify="space-between">
            <Text>بواسطة: اكسب</Text>
            <Text>{dayjs(published_at).fromNow()}</Text>
          </Flex>
        </Box>
      </Card>
    </NextLink>
  );
}
