import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks';
import {getCountry, getLanguage} from '@eksab/util';
import {QueryFunctionContext, QueryKey} from '@tanstack/react-query';

type Winner = {
  id: number;
  image: Media;
};

const queryKey = ['winners'];
const queryFn = (context: QueryFunctionContext<QueryKey>) => {
  const locale = context.queryKey[context.queryKey.length - 1] as string;
  return api
    .get('v2/winners', {headers: {'X-REGION': getCountry(locale), 'X-Locale': getLanguage(locale)}})
    .then((res) => res.data.data);
};

export function useWinnersQuery() {
  return useQuery<Winner[]>(queryKey, queryFn);
}

useWinnersQuery.queryKey = queryKey;
useWinnersQuery.queryFn = queryFn;
