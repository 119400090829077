import dayjs from 'dayjs';

import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks';
import {QueryFunctionContext} from '@tanstack/react-query';
import {getCountry, getLanguage} from '@eksab/util';

const today = dayjs().format('YYYY-MM-DD');
const monthFromToday = dayjs().subtract(1, 'month').format('YYYY-MM-DD');

export interface Winner {
  id: number;
  name: string;
  photo: Media;
  total_amount: string;
}

const queryKey = ['top-winners'];

const queryFn = (context: QueryFunctionContext) => {
  const locale = context.queryKey[context.queryKey.length - 1] as Locale;
  return api
    .get(`v2/top-winners?per_page=10&created-at-from=${monthFromToday}&created-at-to=${today}`, {
      headers: {'X-Locale': getLanguage(locale), 'X-REGION': getCountry(locale)},
    })
    .then((res) => res.data.data);
};

export const useTopWinners = () => useQuery<Winner[]>(queryKey, queryFn);

useTopWinners.queryKey = queryKey;
useTopWinners.queryFn = queryFn;
