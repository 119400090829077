import {positions} from './const';
import {filters} from './hooks/useCompetitionsQuery/filters';

export type Position = (typeof positions)[number];
export type CompetitionsFilter = (typeof filters)[number] | 'my-competitions';

// TODO: NEED TO CHANGE TO STRINGS
export enum CompetitionType {
  FANTASY = 2,
  PREDICTION = 1,
}

type Sponsor = {
  id: number;
  name: string;
  stroke_colors: {
    color_1: string | null;
    color_2: string | null;
  } | null;
  logo: Media;
  logo_link: string | null;
  image: Media;
  image_link: string | null;
};

export type Competition = {
  id?: number;
  type: CompetitionType;
  has_custom_name: boolean;
  name: string;
  subtitle: string | null;
  structured_name: {
    prize: string;
    winners: string;
  };
  featured: boolean;
  joined?: boolean;
  boostable: boolean;
  is_withdrawable?: boolean;
  participants: {
    min: number | null;
    max: number | null;
    current: number;
  };
  entry_fee: number;
  calculate_reward?: 0 | 1;
  winning_strategy: 'top-1' | 'top-3' | 'top-10' | null;
  reward: number;
  budget: string;
  state: 'upcoming' | 'running' | 'past';
  status: 'approved' | 'pending-approval';
  leagues: {id: number; name: string; logo: Media}[];
  creator: {
    id: number;
    name: string;
  } | null;
  season: {
    id?: number;
    start?: string;
    end?: string;
    game_count: number;
  };
  prizes?:
    | {
        id: number;
        name: string;
        price: number;
        is_active: boolean;
        icon: Media;
        image: Media;
      }[]
    | [undefined];
  sponsors: [Sponsor] | [];
  logo?: Media;
  cover?: Media;
  friends_engagement_message?: string;
  entry?: {id: number; booster?: {config: {cap: string; value: string}; id: number; user_power_id: number}};
  fantasy_limit?: {
    game_count: number;
    player_limit: number;
  };
  tier: 'training' | 'amateur' | 'pro' | 'flex';
  joined_friends?: number;
};

export type Participant = {
  user: {
    id: number;
    name: string;
    email: string;
    photo: Media;
  };
  ranking: number;
  points: string;
  change: 'N/A';
  boosted: boolean;
};

type Value = {
  value: number | null;
  featured: boolean;
};

type Stats = {
  goals: Value;
  saves: Value;
  blocks: Value;
  assists: Value;
  tackles: Value;
  red_cards: Value;
  appearances: Value;
  cleansheets: Value;
  yellow_cards: Value;
};

type PointBreakdown = {
  event_type_group: 'playing-time' | 'cleansheets' | 'successful-passes' | 'cards' | 'goals' | 'assists';
  amount: number;
};

export type TeamPlayer = {
  id: number;
  name: string;
  common_name: string;
  position: Position;
  number: number | null;
  price: number;
  fantasy_points: number;
  stats: Stats;
  image: Media;
  latest_news: any[];
  team: Team;
};

export type Player = {
  id: number;
  name: string;
  common_name: string;
  position: Position;
  number: number | null;
  price: number;
  fantasy_points: number;
  stats: Stats;
  image: Media;
  latest_news: any[];
  team: Team;
  is_captain?: 0 | 1;
  is_vice_captain?: 0 | 1;
  is_substitute?: 0 | 1;
  //  Tells if a player was substituted in a user's lineup (whether he's a lineup or a sub player)
  is_substituted?: boolean;
  points_breakdown?: PointBreakdown[];
  scored_fantasy_points?: number;
  status?: 'played' | 'sidelined';
  selection_status?:
    | {
        status: 'valid' | 'invalid-player';
        meta: null;
      }
    | {
        status: 'invalid-position';
        meta: {
          correct_position: Position;
        };
      };
};

export type Players = [(Player | null)[], (Player | null)[], (Player | null)[], (Player | null)[]];

export type QuestionType = 'SELECT_LIST' | 'COUNTER' | 'YES_NO';

export type ScoreBreakdown = {
  player: Player;
  stats: {
    assists: number;
    cards: number;
    cleansheets: number;
    goals: number;
    penalties: number;
    'playing-time': number;
    points: number;
    'successful-passes': number;
  };
}[];

export type Answer = {
  label: string;
  value: string | number;
};

export type Bet = {
  id: number;
  active_from: string;
  active_to: string;
  points: number;
  current_user_prediction: {
    id: number;
    user_id: number;
    points: number | null;
    value: string;
    created_at: string;
    updated_at: string;
  } | null;
} & (
  | {
      question_type: QuestionType & ('SELECT_LIST' | 'YES_NO');
      question: {body: string; answers: Answer[]};
    }
  | {
      question_type: QuestionType & 'COUNTER';
      question: {body: string};
    }
);

export type FiltersT = {price_from: number; price_to: number; team: string; q: string};
