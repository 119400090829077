import {useRef} from 'react';
import {useRouter} from 'next/router';
import {Container, ContainerProps, Flex, FlexProps, Text, Image} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons';

import {type I18nKey, useTranslate} from '@eksab/i18n';
import {getLanguage} from '@eksab/util';

type Endorsement = {
  endorser: `home.endorsed-by.endorser.${string}` & I18nKey;
  url: string;
};

const endorsements: Endorsement[] = [
  {
    endorser: 'home.endorsed-by.endorser.essam-elhadary',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5525.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.mohamed-zidan',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5529.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.mohamed-abu-gabl',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5526.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.mahmoud-hamdy',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5530.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.marwan-serry',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5528.PNG',
  },
];

export function EndorsedBy(props: ContainerProps) {
  const t = useTranslate();

  return (
    <Container as="section" flexDir="column" alignItems="center" textAlign="center" {...props}>
      <Text as="h2" fontWeight="600" fontSize={['sm', 'md', 'lg', '2xl']} color="#b6b6b6" mb={[0.5, 1, 2]}>
        {t('home.endorsed-by.title')}
      </Text>

      <Text maxW="50rem" fontWeight="700" fontSize={['xl', '2xl', '3xl', '4xl']} mb={[5, 5.5, 6]}>
        {t('home.endorsed-by.description')}
      </Text>

      <Slider>
        {endorsements.map((endorsement) => (
          <Flex key={endorsement.endorser} shrink="0" flexDir="column" w="full" scrollSnapAlign="center">
            <Flex
              w="full"
              justify="center"
              pos="relative"
              isolation="isolate"
              overflow="hidden"
              _before={{
                content: '""',
                pos: 'absolute',
                inset: 0,
                bg: `url(${endorsement.url})`,
                bgSize: 'cover',
                bgPos: 'center',
                filter: 'auto',
                blur: '10px',
                rounded: 'inherit',
                zIndex: -1,
              }}
              rounded="3xl"
              sx={{aspectRatio: '16 / 9'}}
            >
              <Image
                src={endorsement.url}
                alt={t(endorsement.endorser)}
                loading="lazy"
                decoding="async"
                objectFit="contain"
              />
            </Flex>

            <Text
              mt="6"
              mb="4"
              fontSize={['sm', 'md', 'lg', '2xl']}
              fontWeight="700"
              textAlign="center"
              bgClip="text"
              bgGradient="linear(to-t, green.600, green.400)"
            >
              {t(endorsement.endorser)}
            </Text>

            <Flex
              alignSelf="center"
              w="32"
              h="1px"
              bgGradient="linear(to-r, transparent 0%, green.500 50%, transparent 100%)"
            />
          </Flex>
        ))}
      </Slider>
    </Container>
  );
}

const Slider = (props: FlexProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  return (
    <Flex as="article" pos="relative" isolation="isolate" align="center" gap="10" maxW="full" {...props}>
      <SliderButton
        dir="start"
        sliderRef={sliderRef}
        pos={['absolute', null, 'static']}
        top="40%"
        translateY="-50%"
        insetStart="-3.5"
      />

      <Container
        ref={sliderRef}
        as="section"
        variant="scroll"
        gap="8"
        px="0"
        mx="0"
        scrollBehavior="smooth"
        sx={{scrollPaddingInline: 0}}
        w="40rem"
      >
        {props.children}
      </Container>

      <SliderButton
        dir="end"
        sliderRef={sliderRef}
        pos={['absolute', null, 'static']}
        top="40%"
        translateY="-50%"
        insetEnd="-3.5"
      />
    </Flex>
  );
};

interface SliderButtonProps extends FlexProps {
  dir: 'start' | 'end';
  sliderRef: React.RefObject<HTMLDivElement>;
}

const SliderButton = ({dir, sliderRef, ...props}: SliderButtonProps) => {
  const {locale} = useRouter();

  const t = useTranslate();

  const transformDir = (getLanguage(locale) === 'ar' ? -1 : 1) * (dir === 'start' ? -1 : 1);

  return (
    <Flex
      as="button"
      title={t(`app.tooltip.${dir === 'start' ? 'back' : 'next'}`)}
      shrink="0"
      boxSize={[10, 12, 16]}
      bg="gray.500"
      rounded="full"
      align="center"
      justify="center"
      zIndex="1"
      transform="auto"
      transition={['background-color 0.2s ease-in-out, transform 0.2s ease-in-out']}
      _hover={{bg: 'gray.700'}}
      _active={{scale: 0.9}}
      {...props}
      onClick={(e) => {
        sliderRef.current!.scrollLeft =
          sliderRef.current!.scrollLeft + sliderRef.current!.getBoundingClientRect().width * transformDir;

        props.onClick?.(e);
      }}
    >
      <ChevronRightIcon w="50%" h="auto" color="white" transform={`scaleX(${transformDir})`} />
    </Flex>
  );
};
