import {useEffect} from 'react';
import {GetStaticProps} from 'next';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {dehydrate, QueryClient} from '@tanstack/react-query';
import {Container, Heading} from '@chakra-ui/layout';

import {useTranslate} from '@eksab/i18n';
import {type League, useLeaguesQuery, useAppSettingsQuery} from '@eksab/hooks';
import {TodayMatches, useTodayMatchesQuery} from '@eksab/components/today-matches';
import {EarnCoinsBanner} from '@eksab/components/EarnCoinsBanner';

import {
  FeaturedCompetition,
  Leagues,
  TopWinners,
  Winners,
  JoinFacebookGroup,
  LatestNews,
  Footer,
  EndorsedBy,
} from '@eksab/features/home/components';
import {useFeaturedCompetitionQuery, useNewsQuery, useTopWinners, useWinnersQuery} from '@eksab/features/home/hooks';
import {useCompetitionsQuery} from '@eksab/features/competition/hooks';
import {getLanguage} from '@eksab/util/helpers';
import {config} from '@eksab/config';
import {RegionSettings, useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {useFiltersState} from '@eksab/features/competition/components/filters/Filters';
import {useAuthStore} from '@eksab/features/auth/state';

export default function Home() {
  const router = useRouter();

  const t = useTranslate();

  const {data: regionSettings} = useRegionSettings();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  useEffect(() => {
    mixpanel?.track('Page View', {page: 'Home'});
  }, []);
  const title = `${t('app.home')} | ${t('app.name')}`;
  return (
    <>
      <Head>
        <meta name="facebook-domain-verification" content={config.fbDomainVerificationCode} />
        <meta name="description" content="Predict game score and win prizes | Eksab" />
        <title>{title}</title>
      </Head>

      <FeaturedCompetition />

      <TodayMatches />

      {isLoggedIn && (
        <Container my="8">
          <EarnCoinsBanner mx="auto" w={['full', '900px']} />
        </Container>
      )}

      <Container pt="20" pb="16" flexDir="column" pos="relative" overflowX="visible">
        <Leagues />
      </Container>

      {!!regionSettings?.settings?.show_latest_winners && (
        <Container py="20" variant="pattern-light" maxW="unset" px="0">
          <Container flexDir="column">
            <Heading size="2xl" mb="6">
              {t('home.latest-winners')}
            </Heading>

            <TopWinners />
          </Container>
        </Container>
      )}

      {getLanguage(router.locale) === 'ar' && (
        <Container pt="20" flexDir="column" pos="relative">
          <Heading size="2xl" mb="6">
            {t('home.latest_news')}
          </Heading>
          <LatestNews pb="20" />
        </Container>
      )}

      <Container variant="pattern-dark" maxW="unset" px="0">
        <JoinFacebookGroup />
      </Container>

      <Container py="20" variant="pattern-light" maxW="unset" px="0">
        <Container flexDir="column" pos="relative">
          <Heading size="2xl" mb="6">
            {t('home.eksab_winners')}
          </Heading>
          <Winners />
        </Container>
      </Container>

      <EndorsedBy my="20" />

      <Footer />
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
  try {
    const queryClient = new QueryClient();

    const [leagues, regionSettings] = await Promise.all([
      queryClient.fetchQuery<League[]>([...useLeaguesQuery.queryKey, locale], useLeaguesQuery.queryFn),
      queryClient.fetchQuery<RegionSettings>([useRegionSettings.queryKey, locale], useRegionSettings.queryFn),
      queryClient.prefetchQuery([...useFeaturedCompetitionQuery.queryKey, locale], useFeaturedCompetitionQuery.queryFn),
      queryClient.prefetchQuery([...useAppSettingsQuery.queryKey, locale], useAppSettingsQuery.queryFn),
      queryClient.prefetchQuery([...useTodayMatchesQuery.queryKey, locale], useTodayMatchesQuery.queryFn),
      queryClient.prefetchQuery([...useTopWinners.queryKey, locale], useTopWinners.queryFn),
      queryClient.prefetchQuery([...useWinnersQuery.queryKey, locale], useWinnersQuery.queryFn),
      getLanguage(locale) === 'ar'
        ? queryClient.prefetchQuery([...useNewsQuery.queryKey(false), locale], useNewsQuery.queryFn(false))
        : null,
    ]);

    if (leagues.length > 0) {
      await queryClient.prefetchInfiniteQuery(
        useCompetitionsQuery.queryKey(useFiltersState.emptyState(), 'guest', locale),
        (context) => useCompetitionsQuery.queryFn(context, useFiltersState.emptyState(), false),
        {meta: {isTacticsEnabled: regionSettings.settings.tactic_enabled}},
      );
    }

    return {
      props: {
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      },
      // regenerate every 1 hours
      revalidate: 60 * 60 * 1,
    };
  } catch (e) {
    return {props: {}, revalidate: 60};
  }
};
